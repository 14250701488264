module.exports = {
  baseUrl: 'https://api.rxhometest.com',
  // baseUrl: 'http://localhost:4000',
  // fileBaseUrl: 'https://rxhometest.s3.amazonaws.com/web',
  fileBaseUrl: 'https://rxhometest.sfo3.digitaloceanspaces.com/web',
  siteBaseUrl: 'https://rxhometest.com',
  qrUrl: 'https://qr.rxhometest.com',
  // qrUrl: 'http://localhost:8081',
};

// module.exports = {
//   // baseUrl: 'https://api-test.rxhometest.com',
//   baseUrl: 'http://localhost:4000',
//   // fileBaseUrl: 'https://rxhometest.s3.amazonaws.com/web',
//   fileBaseUrl: 'https://rxhometest.sfo3.digitaloceanspaces.com/web',
//   siteBaseUrl: 'https://test.rxhometest.com',
//   qrUrl: 'https://qr-test.rxhometest.com',
// };